.footer {
  height: 64px;
  width: 100vw;
  background-color: transparent;
  max-width: 1920px;
  margin: 0 auto;
}
.footer a {
  padding-top: 15px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: transparent;
  float: right;
}

.bar {
  height: 64px;
  width: 100vw;
  background-color: transparent;
  z-index: 2;
  display: block;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
}

.footer .social {
  padding-right: 32px;
}

.fa-twitter {
  transition: 0.5s;
}

.fa-linkedin {
  transition: 0.5s;
}

.fa-paper-plane {
  transition: 0.5s;
}

.fa-twitter :hover {
  color: #aaaaaa;
}

.fa-linkedin :hover {
  color: #aaaaaa;
}

.fa-paper-plane :hover {
  color: #aaaaaa;
}

.fp-viewing-home .social .fa-paper-plane {
  color: var(--color-brand-charcoal);
}

.fp-viewing-home .social .fa-twitter {
  color: var(--color-brand-charcoal);
}

.fp-viewing-home .social .fa-linkedin {
  color: var(--color-brand-charcoal);
}

.fp-viewing-about .social .fa-paper-plane {
  color: var(--color-brand-green);
}

.fp-viewing-about .social .fa-twitter {
  color: var(--color-brand-green);
}

.fp-viewing-about .social .fa-linkedin {
  color: var(--color-brand-green);
}

.fp-viewing-services .social .fa-paper-plane {
  color: var(--color-brand-blue);
}

.fp-viewing-services .social .fa-twitter {
  color: var(--color-brand-blue);
}

.fp-viewing-services .social .fa-linkedin {
  color: var(--color-brand-blue);
}

.fp-viewing-team .social .fa-paper-plane {
  color: var(--color-brand-purple);
}

.fp-viewing-team .social .fa-twitter {
  color: var(--color-brand-purple);
}

.fp-viewing-team .social .fa-linkedin {
  color: var(--color-brand-purple);
}

.fp-viewing-contact .social .fa-paper-plane {
  color: var(--color-brand-red);
}

.fp-viewing-contact .social .fa-twitter {
  color: var(--color-brand-red);
}

.fp-viewing-contact .social .fa-linkedin {
  color: var(--color-brand-red);
}
