.home mainlogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.home img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5%;
  padding-bottom: 60px;
  height: 50%;
}

.home .scroll {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
  width: 40px;
}

.home .scroll a {
  text-decoration: none;
  opacity: 0.4;
}

.home .scroll a:hover {
  opacity: 0.9;
}

@media screen and (max-width: 1440px) {
  .home img {
    padding-bottom: 32px;
    height: 250px;
  }

  .home .scroll {
    margin-top: 50px;
  }
}

@media screen and (max-width: 1280px) {
  .home img {
    padding-bottom: 32px;
    height: 150px;
  }
}

@media screen and (max-height: 767px) {
  .home .scroll {
    visibility: hidden;
    height: 0;
    width: 0;
  }

  .home img {
    padding-top: 32px;
    padding-bottom: 32px;
    height: 150px;
  }

  .fittomobile {
    margin: 0 auto;
  }

  .home .moderate {
    width: 93%;
  }
}

@media screen and (max-width: 992px) {
  .home .scroll {
    visibility: hidden;
    height: 0;
    width: 0;
  }

  .home img {
    padding-top: 32px;
    padding-bottom: 32px;
    height: 150px;
  }

  .fittomobile {
    margin: 0 auto;
  }

  .home .moderate {
    width: 93%;
  }
}
