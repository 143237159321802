.contact h1{
    color: var(--color-brand-red);
}

.contact h2{
    color: var(--color-brand-red);
}

.contact a{
    color: var(--color-brand-red);
    text-decoration: none;
}

.socialicons a{
    padding-left: 17.5px;
    padding-right: 17.5px;
    text-decoration: none;
}

.contact mapouter{
    text-align:right;
    height:300px;
    width:900px;
}

.contact gmap_canvas {
    margin-right: 35px;
    overflow:hidden;
    background:none !important;
    height:300px;
    width:900px;
}

.socialicons img{
    margin:0px;
    padding:0px;
    height: 48px;
}

.socialicons{
    padding-bottom: 10px;
}

#linkbreak{
    padding-bottom: 10px;
}

padd{
    width: 90px;
}

.contact .broad{
    width: 65%;
}

.mapembed{
    padding-bottom: 1em;
}

.address p{
    margin-bottom: 0;
}

.mobile p{
    display: none;
}

@media screen and (max-height: 649px){
    #maincontent{
        width: 100%;
    }

    .mapembed{
        width: 0px !important;
        height: 0px !important;
        visibility: hidden;
    }

    .desktop{
        visibility: hidden;
        height: 0px !important;
        width: 0px !important;
        font-size: 0px !important;
    }

    .mobile p{
        display: block;
    }
}


@media screen and (max-width: 999px){
    #maincontent{
        width: 100%;
    }
    
    .mapembed{
        width: 0px !important;
        height: 0px !important; 
        visibility: hidden;
    }

    .desktop{
        visibility: hidden;
        height: 0px !important;
        width: 0px !important;
        font-size: 0px !important;
      }

      .mobile p{
        display: block;
    }
  }
