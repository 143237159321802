/* app themes */
.TaptuWebsiteApp {
  --color-brand-primary: green;
}

/* I would remove most styles from this css to indiviudal component css */
.TaptuWebsiteApp {
  text-align: center;
}

.TaptuWebsiteApp-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.TaptuWebsiteApp-header {
  background-color: var(--color-brand-primary);
  height: 150px;
  padding: 20px;
  color: white;
}

.TaptuWebsiteApp-title {
  font-size: 1.5em;
}

.TaptuWebsiteApp-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
