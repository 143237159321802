.sidemenu a {
  text-decoration: none;
  color: var(--color-brand-white);
  transition: color 0.3s ease;
  float: none;
  padding: 0;
}

#menuToggle {
  display: block;
  position: absolute;
  top: 20px;
  right: 32px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: var(--color-brand-charcoal);
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: var(--color-brand-charcoal);
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  opacity: 1;
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: fixed;
  margin: -100px 0 0 0;
  padding: 32px;
  padding-top: 125px;
  right: 0px;

  background: #ffffff;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;

  transform-origin: 0% 0%;
  transform: translate(100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  font-family: "Segoe UI", "Open Sans", "Arial", "sans-serif";
  display: list-item;
  padding: 10px 0;
  font-size: 15px !important;
  color: var(--color-brand-charcoal);
}

#menuToggle input:checked ~ ul {
  transform: scale(1, 1);
  opacity: 1;
}

#menu .soicals {
  padding-top: 15px;
}

#menu .soicalicon {
  padding: 15px;
}
