.services h1 {
  color: var(--color-brand-blue);
}

.services h2 {
  color: var(--color-brand-blue);
}

.services h3 {
  color: var(--color-brand-blue);
}

#hexGrid {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  overflow: hidden;
  list-style-type: none;
}

.services ul {
  padding: 0px;
}

.hex {
  position: relative;
  visibility: hidden;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
}
.hex::after {
  content: "";
  display: block;
  padding-bottom: 86.602%; /* =  100 / tan(60) * 1.5 */
}
.hexIn {
  position: absolute;
  width: 96%;
  padding-bottom: 110.851%; /* =  width / sin(60) */
  margin: 0 2%;
  overflow: hidden;
  visibility: hidden;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
  -webkit-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  -ms-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
}
.hexIn * {
  position: absolute;
  visibility: visible;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
}

.hexPur {
  vertical-align: middle;
  position: absolute;
  width: 96%;
  padding-bottom: 110.851%; /* =  width / sin(60) */
  margin: 0 2%;
  overflow: hidden;
  visibility: hidden;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
  -webkit-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  -ms-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
}
.hexPur * {
  background-color: var(--color-brand-blue);
  position: absolute;
  visibility: visible;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
  transition: 0.5s;
}

.hexLink {
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  -ms-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
}

.hexRed * {
  background-color: var(--color-brand-red);
  transition: 0.5s;
}

.hexPurple * {
  background-color: var(--color-brand-purple);
  transition: 0.5s;
}

.hexGreen * {
  background-color: var(--color-brand-green);
  transition: 0.5s;
}

.hexTrans * {
  background-color: transparent;
  background-image: url("../../assets/HFoHServiceArcs.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hexTrans .hexLink h3 {
  color: var(--color-brand-blue);
  font-family: "OrkneyMed";
}

.hexTrans .hexLink h3 {
  background: none;
}

.halfopacity {
  opacity: 0.5;
  transition: 0.5s;
}

/*** HEX CONTENT **********************************************************************/
.hex h3 {
  margin: 0%;
  font-size: 100%;
  color: white;
  text-align: center;
  right: 0px;
  left: 0px;
  padding-right: 5px;
  padding-left: 2px;
}

@media screen\0 {
  .hex h3 {
    top: 30%;
  }
}

/*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
@media (min-width: 1201px) {
  /* <- 4-3  hexagons per row */
  #hexGrid {
    padding-bottom: 5.5%;
  }
  .hex {
    width: 25%; /* = 100 / 4 */
  }
  .hex:nth-child(7n + 5) {
    /* first hexagon of even rows */
    margin-left: 12.5%; /* = width of .hex / 2  to indent even rows */
  }
}

/*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
@media (min-width: 1201px) {
  /* <- 4-3  hexagons per row */
  #hexGrid {
    padding-bottom: 5.5%;
  }
  .hex {
    width: 25%; /* = 100 / 4 */
  }
  .hex:nth-child(7n + 5) {
    /* first hexagon of even rows */
    margin-left: 12.5%; /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 1200px) and (min-width: 1000px) {
  /* <- 4-3  hexagons per row */
  #hexGrid {
    padding-bottom: 5.5%;
  }
  .hex {
    width: 25%; /* = 100 / 4 */
  }
  .hex:nth-child(7n + 5) {
    /* first hexagon of even rows */
    margin-left: 12.5%; /* = width of .hex / 2  to indent even rows */
  }
}

@media screen and (max-height: 649px) {
  .buttons {
    visibility: hidden;
    height: 0px;
  }

  .exploremap {
    visibility: hidden;
    height: 0px;
  }
}

@media screen and (max-width: 999px) {
  .buttons {
    visibility: hidden;
    height: 0px;
  }

  #desktop {
    visibility: hidden;
    height: 0px;
  }
}

.services .Collapsible__trigger {
  color: var(--color-brand-blue);
}

.services .Collapsible__contentInner h3 {
  visibility: hidden;
  height: 0;
  width: 0;
  margin: 0em;
}

.services .Collapsible__trigger:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f078";
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}

.services .Collapsible__trigger.is-open:after {
  transform: rotateZ(-180deg);
}

.services .faded li {
  color: var(--color-brand-charcoal);
  margin-top: 0em;
  margin-bottom: 1em;
  margin-left: 2.5em;
}

.services .faded a {
  color: var(--color-brand-blue);
  text-decoration: none;
}

.services .faded a:hover {
  color: var(--color-brand-charcoal);
}

.clients li {
  color: var(--color-brand-charcoal);
  font-family: "Segoe UI", "Open Sans", "Arial", "sans-serif";
  line-height: 1.5em;
  font-size: 0.85em;
  margin-left: 0em !important;
  margin-bottom: 0.2em !important;
  list-style: none;
}

.businessclients h3 {
  color: var(--color-brand-purple);
}

.tertiaryclients h3 {
  color: var(--color-brand-red);
}
