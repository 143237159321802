.content {
  width: 100%;
  
  
}
.container {
  z-index: 1;
  max-width: 1862px;
  padding-left: 69px;
  padding-right: 69px;
}

@media screen and (min-width: 1921px){
  .container{
    margin-left: auto; 
    margin-right: auto;
  }
}

@media screen and (max-width: 1200px){
  .container{
    padding-top: 0;
    padding-left: 32px;
    padding-right: 32px;
  }
}