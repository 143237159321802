@font-face {
  font-family: "OrkneyBold";
  src: url("./assets/fonts/orkney/orkney-bold-webfont.woff") format("woff");
}

@font-face {
  font-family: "OrkneyMed";
  src: url("./assets/fonts/orkney/orkney-medium-webfont.woff") format("woff");
}

@font-face {
  font-family: "OrkneyReg";
  src: url("./assets/fonts/orkney/orkney-regular-webfont.woff") format("woff");
}

@font-face {
  font-family: "OrkneyLight";
  src: url("./assets/fonts/orkney/orkney-light-webfont.woff") format("woff");
}

#background {
  background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url(assets/bg1.png) no-repeat center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-size: cover;
}
:root {
  --color-brand-charcoal: #53565a;
  --color-brand-green: #00b050;
  --color-brand-blue: #0078b0;
  --color-brand-purple: #8f00dd;
  --color-brand-red: #d73c00;
  --color-brand-white: #ffffff;
}
/* app themes */
.HfohWebsiteApp {
  display: flex;
  flex-direction: column;
}

h1 {
  font-size: 2.5rem;
  margin-top: 0em;
  margin-bottom: 0rem;
  font-family: "OrkneyBold", "Segoe UI", "Open Sans", "Arial", "sans-serif";
  font-weight: 700;
}

h2 {
  margin-top: 0em;
  margin-bottom: 1em;
  font-size: 1.5rem;
  font-family: "OrkneyMed", "Segoe UI", "Open Sans", "Arial", "sans-serif";
  font-weight: 700;
}

h3 {
  margin-top: 1em;
  margin-bottom: 0.1em;
  font-size: 1.25rem;
  font-family: "OrkneyLight", "Segoe UI", "Open Sans", "Arial", "sans-serif";
  font-weight: 700;
}

p {
  font-size: 1rem;
  font-family: "Segoe UI", "Open Sans", "Arial", "sans-serif";
  color: var(--color-brand-charcoal);
  line-height: 1.5em;
  font-weight: 400;
  margin-top: 0em;
  margin-bottom: 1em;
}

li {
  font-family: "Segoe UI", "Open Sans", "Arial", "sans-serif";
  color: var(--color-brand-charcoal);
  font-weight: 400;
}

.Collapsible__trigger {
  font-size: 1.35rem;
  font-family: "OrkneyMed", "Segoe UI", "Open Sans", "Arial", "sans-serif";
  display: block;
  text-decoration: none;
  position: relative;
  padding-top: 10px;
  font-weight: 700;
  padding-right: 35px;
}

#map-container {
  border: 2px solid white;
  height: 350px;
  max-height: 23vh;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* links */
a {
  font-family: "Segoe UI", "Open Sans", "Arial", "sans-serif";
  font-weight: 400;
  color: var(--color-brand-charcoal);
  cursor: pointer;
}

a:hover {
  color: var(--color-brand-white);
  text-decoration: none;
}

a .fa:hover {
  color: var(--color-brand-white);
  text-decoration: none;
}

a:visited {
  color: var(--color-brand-charcoal);
}

.logo {
  display: block;
  height: 65px;
  float: left;
}

.title {
  padding: 0px;
  width: 100%;
  height: 50px;
}

.main {
  margin-top: 30px;
  width: 100%;
}

.narrow {
  width: 30%;
}

.broad {
  width: 70%;
}

.moderate {
  width: 50%;
}

.left {
  float: left;
}

.right {
  float: right;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#menu-btn-home-text:hover {
  color: var(--color-brand-purple);
}

#menu-btn-head-text:hover {
  color: var(--color-brand-green);
}

.fp-viewing-about #menu-btn-head-text {
  color: var(--color-brand-green);
}

#menu-btn-full-text:hover {
  color: var(--color-brand-blue);
}

.fp-viewing-services #menu-btn-full-text {
  color: var(--color-brand-blue);
}

#menu-btn-of-text:hover {
  color: var(--color-brand-purple);
}

.fp-viewing-team #menu-btn-of-text {
  color: var(--color-brand-purple);
}

#menu-btn-heart-text:hover {
  color: var(--color-brand-red);
}

.fp-viewing-contact #menu-btn-heart-text {
  color: var(--color-brand-red);
}

.fp-viewing-home #navlogo {
  opacity: 0;
}

.fadeIn {
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  -ms-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
}

.fadeOut {
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  -ms-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
}

.faded {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.btn {
  border: 2px solid var(--color-brand-charcoal);
  color: var(--color-brand-charcoal);
  background-color: var(--color-brand-white);
  padding: 2px 16px;
  outline-width: 0px;
  border-radius: 6px;
  display: inline-block;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  margin-bottom: 10px;
  margin-right: 16px;
}

.btn:hover {
  color: #fff;
  background-color: var(--color-brand-charcoal);
}

.students {
  border: 2px solid var(--color-brand-green);
  color: var(--color-brand-green);
}

.students:hover {
  color: #fff;
  background-color: var(--color-brand-green);
}

.studentsclicked {
  color: #fff;
  background-color: var(--color-brand-green);
}

.business {
  border: 2px solid var(--color-brand-purple);
  color: var(--color-brand-purple);
}

.business:hover {
  color: #fff;
  background-color: var(--color-brand-purple);
}

.businessclicked {
  color: #fff;
  background-color: var(--color-brand-purple);
}

.education {
  border: 2px solid var(--color-brand-red);
  color: var(--color-brand-red);
}

.education:hover {
  color: #fff;
  background-color: var(--color-brand-red);
}

.educationclicked {
  color: #fff;
  background-color: var(--color-brand-red);
}

.back {
  border: 2px solid var(--color-brand-blue);
  color: var(--color-brand-blue);
}

.back:hover {
  color: #fff;
  background-color: var(--color-brand-blue);
}

.mapembed {
  height: 20vh;
  width: 100%;
}

.mobile {
  visibility: hidden;
  display: none;
  width: 0;
  height: 0;
}

.Collapsible {
  display: none;
}

.about--info {
  display: none;
}

.youngprofss {
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 50px;
}

.businesstrans {
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 45px;
}

@media screen and (max-width: 1440px) {
  h1 {
    font-size: 2.25rem;
  }

  h2 {
    font-size: 1.35rem;
  }

  h3 {
    font-size: 1.125rem;
  }

  p {
    font-size: 0.9rem;
  }

  li {
    font-size: 0.9rem !important;
  }

  .clients li {
    font-size: 0.75em !important;
  }

  .main {
    margin-top: 20px;
  }
  .btn {
    font-size: 0.9rem;
    line-height: 18px;
    margin-bottom: 10px;
    margin-right: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .logo {
    height: 45px;
  }
}

@media screen and (max-width: 1000px) {
  .logo {
    height: 30px;
  }

  .main {
    padding-top: 0;
  }

  .bar {
    visibility: hidden;
    height: 0;
  }
}

@media screen and (max-width: 1024px) {
  .section {
    padding-top: 90px !important;
  }
}

@media screen and (max-height: 650px) {
  .desktop {
    display: none;
    visibility: hidden;
    width: 0 !important;
    height: 0 !important;
    font-size: 0 !important;
  }

  .mobile {
    visibility: visible;
    display: block;
    width: 100% !important;
    height: 100% !important;
  }

  .section {
    height: 100% !important;
    padding-bottom: 0px !important;
    padding-top: 64px !important;
  }

  .logo {
    height: 30px;
  }

  .faded {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }

  .main {
    padding-top: 0px;
  }

  .bar {
    visibility: hidden;
    height: 0;
  }

  .Collapsible {
    display: block;
  }
}

@media screen and (max-width: 999px) {
  .desktop {
    display: none;
    visibility: hidden;
    width: 0 !important;
    height: 0 !important;
    font-size: 0px !important;
  }

  .mobile {
    visibility: visible;
    display: block;
    width: 100% !important;
    height: 100% !important;
  }

  .faded {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }

  .section {
    height: 100% !important;
    padding-bottom: 0px !important;
    padding-top: 64px !important;
  }

  .Collapsible {
    display: block !important;
  }

  .about--info {
    display: block !important;
  }
}

.privacypolicy {
  margin: 100px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 60%;
  text-align: left;
}

.privacypolicy h1 {
  color: var(--color-brand-purple);
}

.privacypolicy a {
  text-decoration: none;
  color: var(--color-brand-purple);
}

.privacypolicy a:hover {
  color: var(--color-brand-charcoal);
}
label {
  cursor: pointer;
  color: var(--color-brand-red);
  text-align: center;
  padding-bottom: 20px;
}

.privacypolicybuttonclose label {
  color: var(--color-brand-purple);
}

.privacypolicyblock {
  display: none;
  padding: 10px;
}

input {
  display: none;
}

input:checked ~ #openlabel {
  display: none;
}

input:checked ~ .topnav-wrapper {
  display: none;
}

input:checked ~ .maincontentblock {
  display: none;
}

input:checked ~ .privacypolicyblock {
  display: block;
}

input:checked ~ .bar {
  display: none;
}

.hide {
  display: none;
}

.show {
  display: block;
}
