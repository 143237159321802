@import url("https://use.fontawesome.com/releases/v5.3.1/css/all.css");
.about h1 {
  color: var(--color-brand-green);
}

.about h2 {
  color: var(--color-brand-charcoal);
}

.about h3 {
  color: var(--color-brand-charcoal);
}

.about a {
  color: var(--color-brand-green);
  text-decoration: none;
}

.about a:hover {
  color: var(--color-brand-charcoal);
}

.about--info h2 {
  color: var(--color-brand-green);
}

.about--info h3 {
  color: var(--color-brand-green);
  margin-top: 0;
}

.about ul {
  margin-top: 0;
  padding: 0;
  margin-bottom: 0.5em;
}

.about--icons {
  width: 6%;
  float: left;
}

.about--info {
  display: block;
}

.narrow a {
  margin: 0%;
  padding: 0%;
  text-decoration: none;
}

.about ul h2:hover {
  color: var(--color-brand-green);
}

.about .Collapsible__trigger {
  color: var(--color-brand-green);
}

.about .Collapsible__contentInner h2 {
  visibility: hidden;
  height: 0;
  width: 0;
  margin: 0em;
}

.about .Collapsible__trigger:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f078";
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}
.about .Collapsible__trigger.is-open:after {
  transform: rotateZ(-180deg);
}
@media screen and (max-width: 999px) {
  .about--info {
    height: 100%;
    width: 100%;
  }
}
