.slick-next:before {
  display: none;
}

.slick-prev:before {
  display: none;
}

.slick-dots {
  display: fixed;
  margin: 0 auto;
}

.testi {
  padding: 5px;
}
.testi p {
  font-style: italic;
}

.testi strong {
  color: var(--color-brand-charcoal);
}

@media screen and (max-width: 1200px) {
  .testi p {
    font-size: 0.9em;
  }

  .testi strong {
    font-size: 0.9em;
  }
}
