#btn-geekify {
  border: 2px solid var(--color-brand-green);
  color: var(--color-brand-green);
  background-color: var(--color-brand-white);
  border-color: var(--color-brand-green);
  padding: 2px 16px;
  outline-width: 0px;
  border-radius: 6px;
  margin: 0 auto;
  display: inline-block;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  float: right;
  white-space: nowrap;
  margin-bottom: 0;
}

#btn-geekify:hover {
  color: #fff;
  background-color: var(--color-brand-green);
}

#btn-geekify:hover #pp {
  color: var(--color-brand-white);
}

#geek ul {
  list-style: none;
}

#geek li {
  color: var(--color-brand-charcoal);
  line-height: 1.5em;
  margin-top: 0em;
  padding-bottom: 0.5em;
  list-style: none;
}

.about--info #geek {
  width: 100%;
}

.leftpad {
  float: left;
  width: 45%;
}
