.topnav-wrapper {
  z-index: 2;
  display: block;
  top: 0;
  position: fixed;
  right: 0;
  left: 0;
}

.topnav {
  height: 96px;
  max-width: 1920px;
  overflow: hidden;
  margin: 0 auto;
  padding-right: 32px;
}

.topnav a {
  text-decoration: none;
  font-size: 1.1em;
  padding-top: 32px;
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 32px;
  float: right;
  color: var(--brand-color-charcoal);
}

.topnav #navlogo {
  padding-left: 32px;
  padding-right: 32px;
  float: left;
}

@media screen and (max-height: 650px) {
  .topnav {
    background-color: white;
    height: 64px;
  }

  .topnav-wrapper {
    background-color: white;
  }

  .topnav a {
    padding-top: 16px;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 1000px) {
  .topnav {
    background-color: white;
    height: 64px;
  }
}

@media screen and (max-width: 1220px) {
  .topnav a {
    padding-top: 16px;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
  }
}
