.team h1 {
  color: var(--color-brand-purple);
}

.team h2 {
  padding: 0px;
  margin: 5px;
  color: var(--color-brand-purple);
}

.team h3 {
  padding: 0px;
  color: var(--color-brand-purple);
}

.profile {
  text-align: left;
}

.profile img {
  max-width: 50%;
  border-radius: 5px;
}

.bio {
  text-align: left;
}

.bio h2 {
  margin-top: 16px;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0;
  color: var(--color-brand-purple);
}

.bio h3 {
  margin: 0;
  padding: 0;
  color: var(--color-brand-purple);
}

.bio img {
  padding-top: 5px;
  padding-bottom: 16px;
}

.team ul {
  padding: 0px;
}

.hexCharcoal {
  background-color: rgb(230, 230, 230);
}

#hexGridTeam {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  overflow: hidden;
  font-size: 15px;
  list-style-type: none;
  margin-bottom: 0;
}

.hexTeam {
  position: relative;
  visibility: hidden;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
}

.hexTeam::after {
  content: "";
  display: block;
  padding-bottom: 86.602%; /* =  100 / tan(60) * 1.5 */
}

.hexInTeam {
  position: absolute;
  width: 96%;
  padding-bottom: 110.851%; /* =  width / sin(60) */
  margin: 0 2%;
  overflow: hidden;
  visibility: hidden;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
  -webkit-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  -ms-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
}

.hexInTeam * {
  position: absolute;
  visibility: visible;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
}

.hexLinkTeam {
  align-items: center;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  -webkit-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  -ms-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
}

/*** HEX CONTENT **********************************************************************/
.hexTeam img {
  left: -100%;
  right: -100%;
  width: auto;
  height: 100%;
  margin: 0 auto;
  -webkit-transform: rotate3d(0, 0, 0, 0deg);
  -ms-transform: rotate3d(0, 0, 0, 0deg);
  transform: rotate3d(0, 0, 0, 0deg);
}

.hexTeam h1 {
  margin: 0%;
  text-align: center;
  right: 0px;
  left: 0px;
  padding-right: 5px;
  padding-left: 2px;
}

.hexTeam .hextop,
.hexTeam .hexbottom {
  width: 100%;
  color: white;
  margin: 0;
  box-sizing: border-box;
  background-color: var(--color-brand-purple);
  font-weight: 300;
  font-size: 1rem;
  -webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.3s ease-out;
  transition: transform 0.2s ease-out, opacity 0.3s ease-out;
}

.hexTeam .hextop {
  bottom: 50%;
  padding-top: 50%;
  z-index: 1;
  -webkit-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.hexTeam .hextop::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 45%;
  width: 10%;
  text-align: center;
}

.hexTeam .hexbottom {
  top: 50%;
  padding-bottom: 50%;
  -webkit-transform: translate3d(0, 100%, 0);
  -ms-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

/*** HOVER EFFECT  **********************************************************************/
.hexLinkTeam:hover .hextop,
.hexLinkTeam:focus .hextop,
.hexLinkTeam:hover .hexbottom,
.hexLinkTeam:focus .hexbottom {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
/*** https://github.com/web-tiki/responsive-grid-of-hexagons ***/

/*  HEXAGON SIZING INSTRUCTIONS */

/*** Width of .hex on Rows ***/
/*
  w = width of the .hex elements in percent
  x = the number of hexagons you want on the odd rows (1st, 3rd, 5th...)

  w = 100 / x
  w = 100 / 8 = 12.5%

  therefore, 

    .hexTeam {
      width: 12.5%; 
    }  

  giving you 8 hexagons on the odd row and 7 hexagons on the even row.
*/

/*** Indent even rows ***/
/*
  The even rows (2nd, 4th,6th...) are indented with margin-left on the first hexagon of even rows.
  
  The Selector: .hex:nth-child(an+b) can select the first hexagon on the even row

    x = the number of hexagons on odd rows(1st, 3rd, 5th...)
    Y = the number of hexagons on even rows(2nd, 4th, 6th...)
    a = x + y
    b = x + 1

  Example for 8 hexagons on odd rows (this means there will be 7 hexagons on even rows):
  
    x = 8
    y = 7
    a = 8 + 7 = 15
    b = 8 + 1 = 9

  The selector is : .hex:nth-child(15n+9)
  
  The value of margin left is half the width of one hexagon so for 8 hexagons on odd row :
    with of hexagons = 12.5% (see "Width of .hex on Rows")
    margin-left = 12.5 / 2 = 6.25%

  therefore, 

    .hexTeam:nth-child(15n+9) {
      width: 6.25%; 
    }  

  giving you a 6.25% margin left in the first hexagon in the odd row.

*/

@media (min-width: 901px) {
  /* <- 5-4  hexagons per row */
  #hexGridTeam {
    padding-bottom: 4.5%;
  }
  .hexTeam {
    width: 16.665%; /* 100 / 6 */
  }
  .hexTeam:nth-child(9n + 6) {
    /* first hexagon of even rows */
    margin-left: 8.33%; /* = width of .hex / 2  to indent even rows */
  }
}

@media (min-width: 901px) and (max-width: 1400px) {
  /* <- 5-4  hexagons per row */
  #hexGridTeam {
    padding-bottom: 4.5%;
  }
  .hexTeam {
    width: 16.665%; /* 100 / 6 */
  }
  .hexTeam:nth-child(9n + 6) {
    /* first hexagon of even rows */
    margin-left: 8.33%; /* = width of .hex / 2  to indent even rows */
  }
  .hexTeam h2 {
    font-size: 0.9em !important;
  }
}

.team a {
  text-decoration: none;
  color: var(--color-brand-purple);
}

.team a:hover {
  color: var(--color-brand-charcoal);
}

.team .Collapsible__trigger {
  color: var(--color-brand-purple);
}

.team .Collapsible__contentInner h2 {
  visibility: hidden;
  height: 0;
  width: 0;
  margin: 0em;
}

.mobile h2 {
  margin-left: 0;
  padding-top: 0.5em;
  margin-bottom: 0;
}

.mobile .Collapsible__trigger {
  font-size: 1.25rem;
  font-family: "OrkneyLight", "Segoe UI", "Open Sans", "Arial", "sans-serif";
  font-weight: 700;
  display: block;
  text-decoration: none;
  position: relative;
  padding-top: 5px;
  padding-right: 35px;
}

.team .Collapsible__trigger:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f078";
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}

.team .Collapsible__trigger.is-open:after {
  transform: rotateZ(-180deg);
}
@media (max-width: 999px) {
  .profile img {
    max-height: 150px;
    max-width: 150px;
  }
}
